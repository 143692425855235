import { isValidMobilePhone } from "@brazilian-utils/brazilian-utils";

class MobilePhoneUtils {
  public isValidMobilePhone(phone: string) {
    return isValidMobilePhone(phone);
  }

  public format(cellphoneNumber: string | undefined): undefined | string {
    if (!cellphoneNumber) return undefined;
    let value = cellphoneNumber;
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "($1) $2");
    value = value.replace(/(\d)(\d{4})$/, "$1-$2");
    return value;
  }

  public FormatNumberToRemoveSpecialChars(
    cellphoneNumber: string | undefined
  ): string | null {
    return cellphoneNumber
      ? cellphoneNumber
          .replaceAll("(", "")
          .replaceAll(")", "")
          .replaceAll("-", "")
      : null;
  }
}

export const mobilePhoneUtils = new MobilePhoneUtils();
