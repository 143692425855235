import { TipoChavePix } from "../models/BankAccount";
import { EstadoCivil, RegimeDeBens } from "../models/Doctor";

class Formatter {
  public formatEstadoCivil(estadoCivil: EstadoCivil) {
    const obj: { [key in EstadoCivil]: string } = {
      CASADO: "casado(a)",
      DIVORCIADO: "divorciado(a)",
      SEPARADO: "separado(a)",
      SOLTEIRO: "solteiro(a)",
      UNIAO_ESTAVEL: "união estável",
      VIUVO: "viúvo(a)",
    };

    return obj[estadoCivil];
  }

  public formatRegimeDeBens(regimeDeBens: RegimeDeBens) {
    const obj: { [key in RegimeDeBens]: string } = {
      COMUNHAO_PARCIAL: "comunhão parcial",
      COMUNHAO_UNIVERSAL: "comunhão universal",
      PARTICIPAL_FINAL_DOS_AQUESTOS: "participação final dos aquestos",
      SEPARACAO_TOTAL: "separação total",
    };

    return obj[regimeDeBens];
  }

  public formatTipoChavePixEnum(tipoChavePix: TipoChavePix): string {
    const obj: { [key in TipoChavePix]: string } = {
      CPF: "CPF",
      CNPJ: "CNPJ",
      EMAIL: "e-mail",
      HASH: "chave aleatória",
      TELEFONE: "telefone",
    };
    return obj[tipoChavePix];
  }
}

export const formatter = new Formatter();
