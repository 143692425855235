import { Link, useParams } from "react-router-dom";
import MainContent from "../MainContainer";
import {
  Flex,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { DoctorSummary } from "./components/DoctorSummary";
import { AddressData } from "../AddressData";
import { useDoctorQueryById } from "../../hooks/use-doctor-query-by-id";
import { DoctorFinancialData } from "./components/DoctorFinancialData";

enum TabsEnum {
  SUMMARY,
  FINANCIAL_DATA,
  ADDRESS_DATA,
  DOCUMENTS,
}

export const Doctor = () => {
  const [activeTab, setActiveTab] = useState<TabsEnum>(TabsEnum.SUMMARY);
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useDoctorQueryById(id);

  if (isLoading) {
    <MainContent>
      <Flex>
        <Spinner />
      </Flex>
    </MainContent>;
  }

  if (data) {
    return (
      <MainContent>
        <Text fontSize="5xl" fontWeight={600}>
          Dr(a) {data.name}
        </Text>
        <Text>Confira os dados do médico</Text>

        <Link to="/medicos">
          <ArrowBackIcon boxSize={8} mt="6" cursor="pointer" />
        </Link>

        <Tabs
          mt={2}
          index={activeTab}
          onChange={(index) => setActiveTab(index)}
          variant="enclosed"
        >
          <TabList>
            <Tab>Sumário</Tab>
            <Tab>Dados financeiros</Tab>
            <Tab>Dados de endereço</Tab>
            <Tab>Documentos</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              {activeTab === TabsEnum.SUMMARY && (
                <DoctorSummary doctor={data} />
              )}
            </TabPanel>
            <TabPanel>
              {activeTab === TabsEnum.FINANCIAL_DATA && (
                <DoctorFinancialData doctorId={data.id} />
              )}
            </TabPanel>
            <TabPanel>
              {activeTab === TabsEnum.ADDRESS_DATA && <AddressData />}
            </TabPanel>
            <TabPanel>
              <h1>Documentos</h1>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </MainContent>
    );
  }

  return (
    <MainContent>
      <Text fontSize="5xl" fontWeight={600}>
        Médico não encontrado
      </Text>
      <Link to="/medicos">Retorne para a página de Médicos</Link>
    </MainContent>
  );
};
