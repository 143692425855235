import { useMutation, useQueryClient } from "react-query";
import { doctorService } from "../services/doctors-service";
import { loggerService } from "../services/logger-service";

export const useRelateDoctorToCompanyMutation = (
  doctorId: string,
  companyId?: string
) => {
  const queryClient = useQueryClient();

  return useMutation(
    () => {
      if (!companyId) throw new Error("doctorCompanyId is not defined");

      return doctorService.relateDoctorToCompany(doctorId, companyId);
    },
    {
      onError: (err) => loggerService.log(err),
      onSuccess: (res) => {
        queryClient.setQueryData(["doctorById", doctorId], res.data);
      },
    }
  );
};
