import { Button, Text, VStack } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { Doctor } from "../../../../../models/Doctor";
import { formatter } from "../../../../../utils/formatter";
import { dateUtil } from "../../../../../utils/date";
import { ContentTitle } from "../../../../ContentTitle";
import { cpfUtils } from "../../../../../utils/cpf";
import { mobilePhoneUtils } from "../../../../../utils/mobile-phone";

interface Props {
  doctor: Doctor;
  setIsEditState: Dispatch<SetStateAction<boolean>>;
}

export default function DoctorPersonalData({ doctor, setIsEditState }: Props) {
  return (
    <VStack alignItems="flex-start" w="300px">
      <ContentTitle title="Dados pessoais" />

      <Text>Nome: {doctor.name}</Text>

      {doctor.cpf && <Text>CPF: {cpfUtils.formatCpf(doctor.cpf)}</Text>}

      {doctor.crm && <Text>CRM: {doctor.crm}</Text>}

      {doctor.dateOfBirth && (
        <Text>
          Data de nascimento:{" "}
          {dateUtil.formatISODateStringToLocale(doctor.dateOfBirth)}
        </Text>
      )}

      {doctor.email && <Text>E-mail: {doctor.email}</Text>}

      {doctor.estadoCivil && (
        <Text>
          Estado civil: {formatter.formatEstadoCivil(doctor.estadoCivil)}
        </Text>
      )}

      {doctor.estadoCivil && doctor.regimeDeBens && (
        <Text>
          Regime de bens: {formatter.formatRegimeDeBens(doctor.regimeDeBens)}
        </Text>
      )}

      {doctor.nationality && <Text>Nacionalidade: {doctor.nationality}</Text>}

      {doctor.pis && <Text>PIS: {doctor.pis}</Text>}

      {doctor.rg && <Text>RG: {doctor.rg}</Text>}

      {doctor.orgaoEmissorRG && (
        <Text>Órgão emissor: {doctor.orgaoEmissorRG}</Text>
      )}

      {doctor.cellphoneNumber && (
        <Text>
          Telefone pessoal: {mobilePhoneUtils.format(doctor.cellphoneNumber)}
        </Text>
      )}

      {doctor.tituloEleitor && (
        <Text>Título de eleitor: {doctor.tituloEleitor}</Text>
      )}

      <Button colorScheme="green" onClick={() => setIsEditState(true)}>
        editar
      </Button>
    </VStack>
  );
}
