import { AxiosInstance } from "axios";
import { API_HOST } from "../config/env";
import { httpClient } from "../clients/http-client";

export interface LoginPayload {
  email: string;
  password: string;
}

export interface LoginResponse {
  expires_in: number;
}

class LysimedApiAuthService {
  private readonly baseUrl: string;
  private readonly httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    const baseUrlFromEnv = API_HOST;

    if (!baseUrlFromEnv) {
      throw new Error(
        "LysimedApiAuthService: Lysimed API Base Url is not defined"
      );
    }

    this.baseUrl = baseUrlFromEnv;
    this.httpClient = httpClient;
  }

  public async login(payload: LoginPayload) {
    return this.httpClient.post<LoginResponse>(
      `${this.baseUrl}/auth/login`,
      payload,
      {
        withCredentials: true,
      }
    );
  }

  public async logout() {
    return this.httpClient.post(`${this.baseUrl}/auth/logout`, null, {
      withCredentials: true,
    });
  }
}

export const lysimedApiAuthService = new LysimedApiAuthService(httpClient);
