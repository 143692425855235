import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { Doctor } from "../../../../../models/Doctor";
import useDebouncer from "../../../../../hooks/use-debouncer";
import { GetCompanyNamesResponse } from "../../../../../services/doctor-companies-service";
import { useDoctorCompanyAutocompleteQuery } from "../../../../../hooks/use-doctor-company-autocomplete-query";
import { useRelateDoctorToCompanyMutation } from "../../../../../hooks/use-relate-doctor-to-company-mutation";

interface Props {
  doctor: Doctor;
}

export function DoctorSearchCompanyName({ doctor }: Props) {
  const [inputValue, setInputValue] = useState<string>("");
  const [isInputActive, setIsInputActive] = useState<boolean>(false);
  const [selectedDoctorCompany, setSelectedDoctorCompany] = useState<
    GetCompanyNamesResponse | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const queryString = useDebouncer(inputValue, 1000);
  const toast = useToast();

  const { data } = useDoctorCompanyAutocompleteQuery(queryString);
  const mutation = useRelateDoctorToCompanyMutation(
    doctor.id,
    selectedDoctorCompany?.id
  );

  const showAutoCompleteMenu = isInputActive && data;

  const handleButtonOnClick = (doctorCompany: GetCompanyNamesResponse) => {
    setSelectedDoctorCompany(doctorCompany);
    setInputValue("");
    setIsInputActive(false);
    onOpen();
  };

  const handleInputOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { relatedTarget } = event;
    if (relatedTarget?.localName !== "button") {
      setIsInputActive(false);
    }
  };

  const handleConfirmOnClick = () => {
    setIsLoading(true);

    if (!selectedDoctorCompany) {
      return toast({
        duration: 9000,
        status: "error",
        title: "Selecione ao menos uma empresa médica!",
        isClosable: true,
      });
    }

    mutation
      .mutateAsync()
      .then(
        () => {
          toast({
            title: "Empresa vinculada com sucesso!",
            duration: 5000,
            status: "success",
            isClosable: true,
          });
          onClose();
        },
        () => {
          toast({
            duration: 9000,
            status: "error",
            title: "Não foi possível vincular uma empresa a este médico!",
            isClosable: true,
          });
        }
      )
      .finally(() => setIsLoading(false));
  };

  return (
    <Box minHeight="100vh" w="300px">
      <Text>Vincule este médico a uma empresa médica:</Text>
      <Input
        placeholder="procure uma empresa por nome"
        value={inputValue}
        onChange={(event) => setInputValue(event.target.value)}
        onFocus={() => setIsInputActive(true)}
        onBlur={handleInputOnBlur}
      />
      {showAutoCompleteMenu && (
        <Box
          alignItems="flex-start"
          border="1px"
          borderTop="0px"
          w="100%"
          borderRadius="0px 0px 6px 6px"
        >
          {data.map((doctorCompany) => (
            <Button
              key={doctorCompany.id}
              variant="ghost"
              w="100%"
              justifyContent="left"
              onClick={() => handleButtonOnClick(doctorCompany)}
            >
              {doctorCompany.razaoSocial}
            </Button>
          ))}
        </Box>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirme:</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="lg">Deseja vincular Dr(a) {doctor.name}</Text>
            <Text fontSize="lg">
              à empresa <Text as="b">{selectedDoctorCompany?.razaoSocial}</Text>
              ?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="green"
              onClick={handleConfirmOnClick}
              isLoading={isLoading}
            >
              confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
