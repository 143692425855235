import { formatCPF, isValidCPF } from "@brazilian-utils/brazilian-utils";

class CpfUtils {
  public isValidCpf(cpf: string) {
    return isValidCPF(cpf);
  }

  public formatCpf(cpf: string) {
    return formatCPF(cpf);
  }
}

export const cpfUtils = new CpfUtils();
