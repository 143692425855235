import { useQuery } from "react-query";
import { doctorService } from "../services/doctors-service";
import { loggerService } from "../services/logger-service";

export const useDoctorsQuery = (name?: string, page?: number) => {
  const { data } = useQuery(
    ["doctors", name, page],
    () => {
      return doctorService.getDoctors(name, page);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 150000,
      onError: (err) => loggerService.log(err),
    }
  );

  return { data: data?.data };
};
