import { useMutation, useQueryClient } from "react-query";
import { bankAccountService } from "../services/bank-account-service";
import { BankAccount } from "../models/BankAccount";
import { loggerService } from "../services/logger-service";

interface MutationParams {
  accountId: string;
  payload: Partial<BankAccount>;
}

export const useBankAccountEditMutation = (
  doctorId?: string,
  doctorCompanyId?: string
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: MutationParams) => {
      return bankAccountService.editAccount(params.accountId, params.payload);
    },
    {
      onError: (err) => loggerService.log(err),
      onSuccess: () =>
        queryClient.refetchQueries(["bankAccounts", doctorId, doctorCompanyId]),
    }
  );
};
