import { useState } from "react";
import { Doctor } from "../../../../models/Doctor";
import { Flex } from "@chakra-ui/react";
import DoctorEditPersonalData from "./components/DoctorEditPersonalData";
import DoctorPersonalData from "./components/DoctorPersonalData";
import { DoctorRelateToCompany } from "./components/DoctorRelateToCompany";
import { DoctorEmergencyContacts } from "./components/DoctorEmergencyContacts";

interface Props {
  doctor: Doctor;
}

export const DoctorSummary = ({ doctor }: Props) => {
  const [isEditState, setIsEditState] = useState<boolean>(false);
  return (
    <Flex wrap="wrap" gap={8}>
      {isEditState ? (
        <DoctorEditPersonalData
          doctor={doctor}
          setIsEditState={setIsEditState}
        />
      ) : (
        <DoctorPersonalData doctor={doctor} setIsEditState={setIsEditState} />
      )}
      <DoctorRelateToCompany doctor={doctor} />
      <DoctorEmergencyContacts doctor={doctor} />
    </Flex>
  );
};
