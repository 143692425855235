import {
  Button,
  Flex,
  FormLabel,
  Input,
  Select,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction, useState } from "react";
import { BankAccount, TipoChavePix } from "../../models/BankAccount";
import { useBankAccountEditMutation } from "../../hooks/use-bank-account-edit-mutation";
import { brasilApiService } from "../../services/brasil-api-service";
import { loggerService } from "../../services/logger-service";

interface Props {
  account: BankAccount;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setIsEditState: Dispatch<SetStateAction<boolean>>;
}

export function AccountEditForm({
  account,
  setIsLoading,
  isLoading,
  setIsEditState,
}: Props) {
  const {
    agencia,
    agenciaDigito,
    chavePix,
    codigoBanco,
    conta,
    contaDigito,
    nomeBanco,
    tipoChavePix,
    id,
  } = account;

  const [formData, setFormData] = useState({
    agencia: agencia ? agencia : 0,
    agenciaDigito: agenciaDigito ? agenciaDigito : 0,
    chavePix: chavePix ? chavePix : "",
    codigoBanco: codigoBanco ? codigoBanco : 0,
    conta: conta ? conta : 0,
    contaDigito: contaDigito ? contaDigito : 0,
    nomeBanco: nomeBanco ? nomeBanco : "",
    tipoChavePix,
  });
  const doctorId = account.doctorId ?? undefined;
  const doctorCompanyId = account.doctorCompanyId ?? undefined;

  const toast = useToast();
  const mutation = useBankAccountEditMutation(doctorId, doctorCompanyId);

  const handleStringInputOnChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = event.target;
    setFormData((state) => ({ ...state, [id]: value }));
  };

  const handleNumberInputOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { id, value } = event.target;
    setFormData((state) => ({ ...state, [id]: Number(value) }));
  };

  const handleCodigoDoBancoOnBlur = async () => {
    setIsLoading(true);

    await brasilApiService
      .getBank(formData.codigoBanco)
      .then(
        (response) => {
          setFormData((state) => ({
            ...state,
            nomeBanco: response.data.fullName,
          }));
        },
        () =>
          toast({
            title: "Não foi encontrado nenhum banco com este código.",
            status: "error",
            duration: 9000,
            isClosable: true,
          })
      )
      .catch((error) => loggerService.log(error))
      .finally(() => setIsLoading(false));
  };

  const handleSaveOnClick = () => {
    setIsLoading(true);

    mutation
      .mutateAsync({
        accountId: id,
        payload: formData,
      })
      .then(
        () => {
          toast({
            duration: 5000,
            title: "Conta atualizada com sucesso",
            isClosable: true,
            status: "success",
          });
        },
        () => {
          toast({
            duration: 9000,
            title: "Não foi possível modificar esta conta.",
            isClosable: true,
            status: "error",
          });
        }
      )
      .finally(() => {
        setIsLoading(false);
        setIsEditState(false);
      });
  };

  return (
    <VStack alignItems="flex-start" spacing={3}>
      <FormLabel w="50%" display="flex">
        <Text minW="200px">Código do banco:</Text>
        <Input
          value={formData.codigoBanco}
          onBlur={handleCodigoDoBancoOnBlur}
          id="codigoBanco"
          onChange={handleNumberInputOnChange}
        />
      </FormLabel>

      <FormLabel w="50%" display="flex">
        <Text minW="200px">Nome do banco:</Text>
        <Input
          value={formData.nomeBanco}
          id="nomeBanco"
          onChange={handleStringInputOnChange}
        />
      </FormLabel>

      <FormLabel w="50%" display="flex">
        <Text minW="200px">Agência:</Text>
        <Input
          value={formData.agencia}
          id="agencia"
          onChange={handleNumberInputOnChange}
        />
      </FormLabel>

      <FormLabel w="50%" display="flex">
        <Text minW="200px">Dígito da agência:</Text>
        <Input
          value={formData.agenciaDigito}
          id="agenciaDigito"
          onChange={handleNumberInputOnChange}
        />
      </FormLabel>

      <FormLabel w="50%" display="flex">
        <Text minW="200px">Conta corrente:</Text>
        <Input
          value={formData.conta}
          id="conta"
          onChange={handleNumberInputOnChange}
        />
      </FormLabel>

      <FormLabel w="50%" display="flex">
        <Text minW="200px">Dígito da conta:</Text>
        <Input
          value={formData.contaDigito}
          id="contaDigito"
          onChange={handleNumberInputOnChange}
        />
      </FormLabel>

      <FormLabel w="50%" display="flex">
        <Text minW="200px">Chave PIX:</Text>
        <Input
          value={formData.chavePix}
          id="chavePix"
          onChange={handleStringInputOnChange}
        />
      </FormLabel>

      <FormLabel w="50%" display="flex">
        <Text minW="200px">Tipo de chave PIX:</Text>
        <Select
          id="tipoChavePix"
          onChange={handleStringInputOnChange}
          value={formData.tipoChavePix ?? ""}
        >
          <option value={TipoChavePix.EMAIL}>Email</option>
          <option value={TipoChavePix.TELEFONE}>Telefone</option>
          <option value={TipoChavePix.CPF}>Cpf</option>
          <option value={TipoChavePix.HASH}>Chave aleatória</option>
          <option value={TipoChavePix.CNPJ}>CNPJ</option>
        </Select>
      </FormLabel>

      <Flex w="50%" alignItems="center">
        <Button
          colorScheme="green"
          onClick={handleSaveOnClick}
          isLoading={isLoading}
          mr={5}
        >
          salvar
        </Button>
        <Button
          colorScheme="green"
          variant="link"
          onClick={() => setIsEditState(false)}
        >
          cancelar
        </Button>
      </Flex>
    </VStack>
  );
}
