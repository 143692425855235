import { Outlet, useLocation } from "react-router-dom";
import { SideNav } from "./components/SideNav";
import { Home } from "./components/Home";
import { Login } from "./components/Login";
import { Flex } from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { IS_USER_LOGGED } from "./constants/cookies";

export const Dashboard = () => {
  const { pathname } = useLocation();
  const [cookies] = useCookies([IS_USER_LOGGED]);

  if (!cookies[IS_USER_LOGGED]) {
    return <Login />;
  }

  return (
    <Flex>
      <SideNav />
      <Outlet />
      {pathname === "/" && <Home />}
    </Flex>
  );
};
