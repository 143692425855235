import { useMutation, useQueryClient } from "react-query";
import { doctorEmergencyContactService } from "../services/doctor-emergency-contact-service";
import { DoctorEmergencyContactScheme } from "../validators/doctor-emergency-contact-validator";
import { loggerService } from "../services/logger-service";

export const useCreateDoctorEmergencyContactMutation = (doctorId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: DoctorEmergencyContactScheme) =>
      doctorEmergencyContactService.createNewContact({ ...data, doctorId }),
    {
      onError: (err) => loggerService.log(err),
      onSuccess: () =>
        queryClient.refetchQueries(["doctorsEmergencyContacts", doctorId]),
    }
  );
};
