import { createBrowserRouter } from "react-router-dom";
import { Dashboard } from "../Dashboard";
import { Doctors } from "../components/Doctors";
import { DoctorCompany } from "../components/DoctorCompany";
import { Modules } from "../constants/modules";
import { Doctor } from "../components/Doctor";
import { CreateDoctor } from "../components/Doctor/components/CreateDoctor";
import { BankAccount } from "../components/BankAccount/BankAccount";
import { ChangePasswordPage } from "../components/ChangePasswordPage/ChangePasswordPage";

export enum Routes {
  doctor = `/${Modules.DOCTORS}`,
  doctorCompany = `/${Modules.DOCTOR_COMPANIES}`,
  bankAccount = `${Modules.BANK_ACCOUNT}`,
}

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
    children: [
      {
        path: `${Routes.doctor}/cadastrar`,
        element: <CreateDoctor />,
      },
      {
        path: `${Routes.doctor}/:id`,
        element: <Doctor />,
      },
      {
        path: Routes.doctor,
        element: <Doctors />,
      },
      {
        path: Routes.doctorCompany,
        element: <DoctorCompany />,
      },
      {
        path: Routes.bankAccount,
        element: <BankAccount />,
      },
    ],
  },
  {
    path: "/mudar-senha",
    element: <ChangePasswordPage />,
  },
]);
