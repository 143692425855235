import { VStack, Alert, AlertIcon, Text } from "@chakra-ui/react";
import { useBankAccountsQuery } from "../../../../hooks/use-bank-accounts-query";
import { ContentTitle } from "../../../ContentTitle";
import { AccountAccordion } from "../../../BankAccount/AccountAccordion";
import AccountForm from "../../../BankAccount/AccountForm";

interface Props {
  doctorId: string;
}

export const DoctorFinancialData = ({ doctorId }: Props) => {
  const { data: accounts } = useBankAccountsQuery({
    doctorId,
  });

  return (
    <VStack alignItems="flex-start" spacing={4}>
      <Text as="b">Contas correntes cadastradas</Text>
      {accounts && accounts.length === 0 && (
        <Alert status="info">
          <AlertIcon />
          <Text>Não há conta corrente cadastrada para este médico.</Text>
        </Alert>
      )}
      {accounts &&
        accounts.map((account, index) => (
          <AccountAccordion
            key={account.id}
            account={account}
            position={index + 1}
          />
        ))}
      <ContentTitle title="Cadastrar uma nova conta corrente" />
      <AccountForm doctorId={doctorId} />
    </VStack>
  );
};
