import { useQuery } from "react-query";
import { doctorService } from "../services/doctors-service";
import { loggerService } from "../services/logger-service";

export const useDoctorQueryById = (id?: string) => {
  const { data, isLoading, error } = useQuery(
    ["doctorById", id],
    () => {
      if (!id) return undefined;

      return doctorService.getDoctorById(id);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 150000,
      onError: (err) => loggerService.log(err),
      retry: false,
    }
  );

  return { data: data?.data, isLoading, error };
};
