import MainContainer from "../MainContainer";
import {
  Button,
  FormLabel,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Flex,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDoctorsQuery } from "../../hooks/use-doctors-query";
import useDebouncer from "../../hooks/use-debouncer";
import { DEBOUNCER_DELAY } from "../../constants/debouncer-delay";
import { TablePagination } from "../TablePagination";
import { cpfUtils } from "../../utils/cpf";

export const Doctors = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchNomeFilter, setSearchNomeFilter] = useState<string>("");
  const nameQueryParam = useDebouncer(searchNomeFilter, DEBOUNCER_DELAY);
  const { data } = useDoctorsQuery(nameQueryParam, currentPage);

  const isPaginationDisabled = currentPage === data?.pages;

  const navigate = useNavigate();

  const handleRowOnClick = (id: string) => {
    navigate(`/medicos/${id}`);
  };

  const handleCreateNewDoctorOnClick = () => {
    navigate("/medicos/cadastrar");
  };

  return (
    <MainContainer>
      <Text fontSize="5xl" fontWeight={600}>
        Médicos
      </Text>
      <Text>Todas os médicos clientes da Lysi</Text>

      <TableContainer maxWidth="1032px" mt={5}>
        <Flex alignItems="center" justifyContent="space-between">
          <FormLabel display="flex" flexDirection="column" maxW="300px">
            Filtrar por nome:
            <Input
              value={searchNomeFilter}
              onChange={(event) => {
                setSearchNomeFilter(event.target.value);
                setCurrentPage(1);
              }}
            />
          </FormLabel>
          <Button
            colorScheme="green"
            mt={2}
            onClick={handleCreateNewDoctorOnClick}
          >
            cadastrar novo médico
          </Button>
        </Flex>
        <Table variant="striped" size={{ base: "sm", "2xl": "md" }}>
          <Thead>
            <Tr>
              <Th>Nome</Th>
              <Th>CPF</Th>
              <Th>CRM</Th>
              <Th>Empresa</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data ? (
              <>
                {data.doctors.map((doc) => (
                  <Tr
                    onClick={() => handleRowOnClick(doc.id)}
                    key={doc.id}
                    cursor="pointer"
                    _hover={{ outline: "2px solid black" }}
                  >
                    <Td>{doc.name}</Td>
                    <Td>{doc.cpf ? cpfUtils.formatCpf(doc.cpf) : ""}</Td>
                    <Td>{doc.crm ?? ""}</Td>
                    <Td>
                      {doc.doctorCompany
                        ? doc.doctorCompany.razaoSocial
                        : "n/a"}
                    </Td>
                  </Tr>
                ))}
              </>
            ) : (
              <Tr>
                <Td>
                  <Text>Carregando...</Text>
                </Td>
                <Td />
                <Td />
                <Td />
              </Tr>
            )}
          </Tbody>
        </Table>

        {data && data.doctors.length > 0 && (
          <TablePagination
            currentPage={currentPage}
            isDisabled={isPaginationDisabled}
            setCurrentPage={setCurrentPage}
          />
        )}
      </TableContainer>
    </MainContainer>
  );
};
