export enum TipoChavePix {
  CPF = "CPF",
  TELEFONE = "TELEFONE",
  EMAIL = "EMAIL",
  HASH = "HASH",
  CNPJ = "CNPJ",
}

export interface BankAccountBtg {
  btgId: string;
}

export interface BankAccount {
  id: string;
  agencia: number;
  agenciaDigito: number | null;
  chavePix: string | null;
  tipoChavePix: TipoChavePix | null;
  nomeBanco: string | null;
  codigoBanco: number;
  conta: number;
  contaDigito: number | null;
  doctorCompanyId: string | null;
  doctorId: string | null;
  bankAccountBtg: BankAccountBtg | null;
}
