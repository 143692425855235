import { VStack } from "@chakra-ui/react";
import { ContentTitle } from "../../../../ContentTitle";
import { Doctor } from "../../../../../models/Doctor";
import { CreateEmergencyContact } from "./CreateEmergencyContact";
import { useDoctorEmergencyContactsQuery } from "../../../../../hooks/use-doctor-emergency-contacts-query";
import { mobilePhoneUtils } from "../../../../../utils/mobile-phone";

interface Props {
  doctor: Doctor;
}

export function DoctorEmergencyContacts({ doctor }: Props) {
  const { data } = useDoctorEmergencyContactsQuery(doctor.id);

  return (
    <VStack w="300px" alignItems="flex-start">
      <ContentTitle title="Contatos de emergência" />
      {data?.length !== 0 ? (
        data?.map((contact) => (
          <h1 key={contact.id}>
            {contact.name}: {mobilePhoneUtils.format(contact.cellphoneNumber)}
          </h1>
        ))
      ) : (
        <h1>Cadastre um novo contato de emergência:</h1>
      )}

      <CreateEmergencyContact doctor={doctor} />
    </VStack>
  );
}
