import { useQuery } from "react-query";
import { doctorEmergencyContactService } from "../services/doctor-emergency-contact-service";
import { loggerService } from "../services/logger-service";

export const useDoctorEmergencyContactsQuery = (doctorId?: string) => {
  const { data } = useQuery(
    ["doctorsEmergencyContacts", doctorId],
    () => doctorEmergencyContactService.getEmergencyContacts(doctorId),
    {
      refetchOnWindowFocus: false,
      staleTime: 150000,
      onError: (err) => loggerService.log(err),
    }
  );

  return { data: data?.data };
};
