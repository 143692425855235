import { z } from "zod";
import { cpfUtils } from "../utils/cpf";
import { mobilePhoneUtils } from "../utils/mobile-phone";
import { EstadoCivil, RegimeDeBens } from "../models/Doctor";

export const doctorScheme = z.object({
  name: z.string({ required_error: "Nome é um campo obrigatório" }),
  email: z.string().email({ message: "Forneça um e-mail válido." }).optional(),
  cpf: z
    .custom<string>(
      (data) => cpfUtils.isValidCpf(data as string),
      "Forneça um CPF válido."
    )
    .optional(),
  cellphoneNumber: z
    .custom<string>(
      (data) => mobilePhoneUtils.isValidMobilePhone(data as string),
      "Forneça um telefone celular válido."
    )
    .optional(),
  crm: z.string().optional(),
  rg: z.string().optional(),
  orgaoEmissorRg: z.string().optional(),
  pis: z.string().optional(),
  dateOfBirth: z.string().datetime().optional(),
  nationality: z.string().optional(),
  estadoCivil: z.nativeEnum(EstadoCivil).optional(),
  regimeDeBens: z.nativeEnum(RegimeDeBens).optional(),
  crmSuplementar: z.string().optional(),
  tituloEleitor: z.string().optional(),
  doctorStatus: z.string().optional(),
  doctorTransferTax: z.string().optional(),
});

export type DoctorScheme = z.infer<typeof doctorScheme>;
