import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  Accordion,
} from "@chakra-ui/react";
import { useState } from "react";
import { BankAccount } from "../../models/BankAccount";
import AccountInformation from "./AccountInformation";
import { AccountEditForm } from "./AccountEditForm";

interface Props {
  position: number;
  account: BankAccount;
}

export function AccountAccordion({ account, position }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditState, setIsEditState] = useState<boolean>(false);

  return (
    <Accordion
      w="950px"
      allowToggle
      borderColor="transparent"
      borderLeft="4px"
      borderLeftColor="gray.300"
    >
      <AccordionItem>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            <Text fontSize="lg">Conta corrente nº {position}</Text>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          {isEditState ? (
            <AccountEditForm
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setIsEditState={setIsEditState}
              account={account}
            />
          ) : (
            <AccountInformation
              account={account}
              setIsLoading={setIsLoading}
              setIsEditState={setIsEditState}
              isLoading={isLoading}
            />
          )}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
