import {
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import { Formik, Field, FormikHelpers } from "formik";
import { validatePassword } from "../../utils/password";
import { lysimedApiAuthService } from "../../services/lysimed-api-auth-service";
import { loggerService } from "../../services/logger-service";
import { emailUtils } from "../../utils/email";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { IS_USER_LOGGED } from "../../constants/cookies";
import { dateUtil } from "../../utils/date";

interface FormData {
  email: string;
  password: string;
}

export const Login = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [, setCookie] = useCookies();

  const formValidate = (data: FormData) => {
    const errors: Partial<FormData> = {};

    if (!data.email || !emailUtils.isValidEmail(data.email)) {
      errors.email = "Insira um e-mail válido";
    }

    if (!data.password || !validatePassword(data.password)) {
      errors.password = "Forneça uma senha forte";
    }

    return errors;
  };

  const handleForgetPasswordOnClick = () => {
    navigate("/mudar-senha");
  };

  const handleOnSubmit = (
    values: FormData,
    helpers: FormikHelpers<FormData>
  ) => {
    lysimedApiAuthService
      .login(values)
      .then(
        (data) => {
          setCookie(IS_USER_LOGGED, "true", {
            expires: dateUtil.addDaysToCurrentDate(data.data.expires_in),
          });
        },
        () => {
          toast({
            status: "error",
            description: "Não foi possível realizar o login",
            isClosable: true,
            duration: 9000,
          });
        }
      )
      .catch((error) => loggerService.log(error))
      .finally(() => helpers.setSubmitting(false));
  };

  return (
    <Box as="main" height="100vh" width={300} mx="auto" display="flex">
      <Box alignSelf="center">
        <Text fontSize="4xl">Bem vindo</Text>
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={(values, helpers) => handleOnSubmit(values, helpers)}
          validate={(data) => formValidate(data)}
        >
          {({ handleSubmit, errors, touched, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <FormControl isInvalid={!!errors.email && touched.email}>
                <FormLabel width="100%">
                  Email:
                  <Field as={Input} type="email" id="email" name="email" />
                </FormLabel>
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.password && touched.password}>
                <FormLabel width="100%">
                  Senha:
                  <Field
                    as={Input}
                    type="password"
                    id="password"
                    name="password"
                  />
                </FormLabel>
                <FormErrorMessage>{errors.password}</FormErrorMessage>
              </FormControl>

              <Button
                variant="link"
                alignSelf="flex-start"
                onClick={handleForgetPasswordOnClick}
                mb={2}
              >
                Esqueci minha senha
              </Button>
              <Button
                type="submit"
                width="100%"
                colorScheme="green"
                isLoading={isSubmitting}
              >
                Entrar
              </Button>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};
