import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { Flex, Button, Text } from "@chakra-ui/react";

interface Props {
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  isDisabled: boolean;
}

export const TablePagination = ({
  currentPage,
  setCurrentPage,
  isDisabled,
}: Props) => {
  return (
    <Flex mt={5} justifyContent="space-between" alignItems="center">
      <Button
        variant="ghost"
        isDisabled={currentPage <= 1}
        onClick={() => setCurrentPage((state) => state - 1)}
      >
        <ArrowLeftIcon boxSize={3} mr={2} />
        Anterior
      </Button>
      <Text>Página: {currentPage}</Text>
      <Button
        variant="ghost"
        isDisabled={isDisabled}
        onClick={() => setCurrentPage((state) => state + 1)}
      >
        Próxima <ArrowRightIcon boxSize={3} ml={2} />
      </Button>
    </Flex>
  );
};
