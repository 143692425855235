import { Box, useToast } from "@chakra-ui/react";
import { Dispatch, SetStateAction, useState } from "react";
import { DoctorForm } from "../../DoctorForm";
import { DoctorScheme } from "../../../../../validators/doctor-scheme-validator";
import {
  Doctor,
  DoctorStatus,
  EstadoCivil,
} from "../../../../../models/Doctor";
import { useEditDoctorMutation } from "../../../../../hooks/use-edit-doctor-mutation";
import { FormikHelpers } from "formik";
import { dateUtil } from "../../../../../utils/date";

interface Props {
  doctor: Doctor;
  setIsEditState: Dispatch<SetStateAction<boolean>>;
}

export default function DoctorEditPersonalData({
  doctor,
  setIsEditState,
}: Props) {
  const initialValues: DoctorScheme = {
    cpf: doctor.cpf ?? "",
    crm: doctor.crm ?? "",
    dateOfBirth: doctor.dateOfBirth ?? "",
    email: doctor.email ?? "",
    estadoCivil: doctor.estadoCivil ?? EstadoCivil.SOLTEIRO,
    doctorStatus: doctor.doctorStatus ?? DoctorStatus.ATIVO,
    nationality: doctor.nationality ?? "",
    name: doctor.name,
    orgaoEmissorRg: doctor.orgaoEmissorRG ?? "",
    pis: doctor.pis ?? "",
    regimeDeBens: doctor.regimeDeBens ?? undefined,
    rg: doctor.rg ?? "",
    cellphoneNumber: doctor.cellphoneNumber ?? "",
    tituloEleitor: doctor.tituloEleitor ?? "",
  };

  const [dateInputValue, setDateInputValue] = useState<string>(
    dateUtil.formatChakraDatetime(doctor.dateOfBirth)
  );
  const toast = useToast();

  const mutation = useEditDoctorMutation(doctor.id);

  const handleSubmitOnClick = (
    values: DoctorScheme,
    helpers: FormikHelpers<DoctorScheme>
  ) => {
    mutation
      .mutateAsync(values)
      .then(
        () => {
          toast({
            title: "Médico editado com sucesso!",
            duration: 5000,
            status: "success",
          });
          setIsEditState(false);
        },
        () => {
          toast({
            title: "Não foi editar os dados do médico!",
            duration: 9000,
            isClosable: true,
            status: "error",
          });
        }
      )
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };

  return (
    <Box w="300px">
      <DoctorForm
        dateInputValue={dateInputValue}
        initialValues={initialValues}
        onSubmit={handleSubmitOnClick}
        setDateInputValue={setDateInputValue}
        setIsEditState={setIsEditState}
      />
    </Box>
  );
}
