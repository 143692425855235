export enum EstadoCivil {
  SOLTEIRO = "SOLTEIRO",
  CASADO = "CASADO",
  SEPARADO = "SEPARADO",
  DIVORCIADO = "DIVORCIADO",
  VIUVO = "VIUVO",
  UNIAO_ESTAVEL = "UNIAO_ESTAVEL",
}

export enum RegimeDeBens {
  COMUNHAO_UNIVERSAL = "COMUNHAO_UNIVERSAL",
  COMUNHAO_PARCIAL = "COMUNHAO_PARCIAL",
  SEPARACAO_TOTAL = "SEPARACAO_TOTAL",
  PARTICIPAL_FINAL_DOS_AQUESTOS = "PARTICIPAL_FINAL_DOS_AQUESTOS",
}

export enum DoctorStatus {
  EM_NEGOCIACAO = "EM_NEGOCIACAO",
  CONTRATACAO = "CONTRATACAO",
  ATIVO = "ATIVO",
  DESLIGADO = "DESLIGADO",
}

interface DoctorCompanyRazaoSocial {
  razaoSocial: string;
}

export interface Doctor {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  email: string | null;
  cpf: string | null;
  cellphoneNumber: string | null;
  crm: string | null;
  rg: string | null;
  orgaoEmissorRG: string | null;
  pis: string | null;
  dateOfBirth: string | null;
  nationality: string | null;
  estadoCivil: EstadoCivil | null;
  regimeDeBens: RegimeDeBens | null;
  crmSuplementar: string | null;
  tituloEleitor: string | null;
  doctorStatus: DoctorStatus | null;
  doctorCompanyId: string | null;
  doctorCompany: DoctorCompanyRazaoSocial | null;
  addressId: string | null;
  doctorTransferTax: number | null;
}
