import { AxiosInstance } from "axios";
import { API_HOST } from "../config/env";
import { httpClient } from "../clients/http-client";

export interface GetCompanyNamesResponse {
  id: string;
  razaoSocial: string;
}

class DoctorCompaniesService {
  private readonly baseUrl: string;
  private readonly httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    const baseUrlFromEnv = API_HOST;

    if (!baseUrlFromEnv) {
      throw new Error(
        "DoctorCompanyService: Lysimed API Base Url is not defined"
      );
    }

    this.baseUrl = baseUrlFromEnv;
    this.httpClient = httpClient;
  }

  public async getCompanyNames(name?: string) {
    const queryParams = new URLSearchParams();

    if (name) queryParams.append("name", name);

    return this.httpClient.get<GetCompanyNamesResponse[]>(
      `${this.baseUrl}/doctor-company/autocomplete?${queryParams.toString()}`,
      {
        withCredentials: true,
      }
    );
  }
}

export const doctorCompaniesService = new DoctorCompaniesService(httpClient);
