import { Box, useToast } from "@chakra-ui/react";
import { FormikHelpers } from "formik/dist/types";
import { useState } from "react";
import { DoctorScheme } from "../../../../../validators/doctor-scheme-validator";
import { EstadoCivil } from "../../../../../models/Doctor";
import { DoctorForm } from "../../DoctorForm";
import { useCreateDoctorMutation } from "../../../../../hooks/use-create-doctor-mutation";
import { useNavigate } from "react-router-dom";

export function Content() {
  const initialValues: DoctorScheme = {
    name: "",
    cpf: "",
    crm: "",
    dateOfBirth: "",
    email: "",
    estadoCivil: EstadoCivil.SOLTEIRO,
    nationality: "",
    orgaoEmissorRg: "",
    pis: "",
    regimeDeBens: undefined,
    rg: "",
    cellphoneNumber: "",
  };

  const [dateInputValue, setDateInputValue] = useState("");
  const toast = useToast();
  const mutation = useCreateDoctorMutation();
  const navigate = useNavigate();

  const handleSubmitOnClick = (
    values: DoctorScheme,
    helpers: FormikHelpers<DoctorScheme>
  ) => {
    mutation
      .mutateAsync(values)
      .then(
        () => {
          toast({
            title: "Médico criado com sucesso!",
            duration: 5000,
            status: "success",
          });
          navigate("/medicos");
        },
        () => {
          toast({
            title: "Não foi possível criar um novo médico!",
            duration: 9000,
            isClosable: true,
            status: "error",
          });
        }
      )
      .finally(() => helpers.setSubmitting(false));
  };

  return (
    <Box maxW="500px">
      <DoctorForm
        dateInputValue={dateInputValue}
        initialValues={initialValues}
        onSubmit={handleSubmitOnClick}
        setDateInputValue={setDateInputValue}
      />
    </Box>
  );
}
