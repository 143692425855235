import { Box, Button, Flex, Text, VStack } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { Routes } from "../../router";
import { useCookies } from "react-cookie";
import { IS_USER_LOGGED } from "../../constants/cookies";
import { lysimedApiAuthService } from "../../services/lysimed-api-auth-service";
import { loggerService } from "../../services/logger-service";

export const SideNav = () => {
  const location = useLocation();
  const [, , removeCookie] = useCookies();

  const isActive = (searchString: string) => {
    return location.pathname.includes(searchString);
  };

  function handleLogout(): void {
    lysimedApiAuthService
      .logout()
      .then(() => {
        removeCookie(IS_USER_LOGGED);
      })
      .catch((err) => loggerService.log(err));
  }

  return (
    <VStack
      as="aside"
      borderRight="4px"
      borderColor="gray.200"
      w="300px"
      minH="100vh"
      p={3}
      spacing={5}
      alignItems="start"
    >
      <Link to="/">
        <img src="/lysimed.png" alt="Lysimed" />
      </Link>
      <nav>
        <ul>
          <Box as="li">
            <Link to={Routes.doctor}>
              <Text
                fontSize="md"
                fontWeight={500}
                color={isActive(Routes.doctor) ? "green.400" : "gray.800"}
                _hover={{ color: "green.400" }}
              >
                Médicos
              </Text>
            </Link>
          </Box>

          <Box as="li" mt={2}>
            <Link to={Routes.doctorCompany}>
              <Text
                fontSize="md"
                fontWeight={500}
                color={
                  isActive(Routes.doctorCompany) ? "green.400" : "gray.800"
                }
                _hover={{ color: "green.400" }}
              >
                Empresas Médicas
              </Text>
            </Link>
          </Box>

          <Box as="li" mt={2}>
            <Link to={Routes.bankAccount}>
              <Text
                fontSize="md"
                fontWeight={500}
                color={isActive(Routes.bankAccount) ? "green.400" : "gray.800"}
                _hover={{ color: "green.400" }}
              >
                Contas Bancárias
              </Text>
            </Link>
          </Box>
        </ul>
      </nav>
      <Flex direction="column" flex={1} justifyContent="flex-end">
        <Button colorScheme="green" width="250px" onClick={handleLogout}>
          Sair
        </Button>
      </Flex>
    </VStack>
  );
};
