import { AxiosInstance } from "axios";
import { BankAccount } from "../models/BankAccount";
import { BankAccountScheme } from "../validators/bank-account-scheme-validator";
import { API_HOST } from "../config/env";
import { httpClient } from "../clients/http-client";

class BankAccountService {
  private readonly baseUrl: string;
  private readonly httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    const baseUrlFromEnv = API_HOST;

    if (!baseUrlFromEnv) {
      throw new Error(
        "BankAccountService: Lysimed API Base Url is not defined"
      );
    }

    this.baseUrl = baseUrlFromEnv;
    this.httpClient = httpClient;
  }

  public async getAccounts(doctorId?: string, doctorCompanyId?: string) {
    const searchParams = new URLSearchParams();

    if (doctorId) searchParams.append("doctorId", doctorId);

    if (doctorCompanyId)
      searchParams.append("doctorCompanyId", doctorCompanyId);

    return this.httpClient.get<BankAccount[]>(
      `${this.baseUrl}/bank-account?${searchParams.toString()}`,
      {
        withCredentials: true,
      }
    );
  }

  public getBtgAuthAuthorizeUrl() {
    return `${this.baseUrl}/btg-bank-account/authorize`;
  }

  public async deleteAccount(accountId: string) {
    return this.httpClient.delete(`${this.baseUrl}/bank-account/${accountId}`, {
      withCredentials: true,
    });
  }

  public async createAccount(payload: BankAccountScheme) {
    return this.httpClient.post<BankAccount>(
      `${this.baseUrl}/bank-account`,
      payload,
      {
        withCredentials: true,
      }
    );
  }

  public async editAccount(accountId: string, payload: Partial<BankAccount>) {
    return this.httpClient.patch<BankAccount>(
      `${this.baseUrl}/bank-account/${accountId}`,
      payload,
      {
        withCredentials: true,
      }
    );
  }
}

export const bankAccountService = new BankAccountService(httpClient);
