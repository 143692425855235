import { useQuery } from "react-query";
import { bankAccountService } from "../services/bank-account-service";
import { loggerService } from "../services/logger-service";

interface Params {
  doctorId?: string;
  doctorCompanyId?: string;
}

export const useBankAccountsQuery = (params?: Params) => {
  const { data } = useQuery(
    ["bankAccounts", params],
    () =>
      bankAccountService.getAccounts(params?.doctorId, params?.doctorCompanyId),
    {
      refetchOnWindowFocus: false,
      staleTime: 150000,
      onError: (err) => loggerService.log(err),
    }
  );

  return { data: data?.data };
};
