import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useCreateDoctorEmergencyContactMutation } from "../../../../../hooks/use-create-doctor-emergency-contact-mutation";
import { Doctor } from "../../../../../models/Doctor";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import {
  DoctorEmergencyContactScheme,
  doctorEmergencyContactScheme,
} from "../../../../../validators/doctor-emergency-contact-validator";
import { mobilePhoneUtils } from "../../../../../utils/mobile-phone";

interface Props {
  doctor: Doctor;
}

export const CreateEmergencyContact = ({ doctor }: Props) => {
  const mutation = useCreateDoctorEmergencyContactMutation(doctor.id);
  const toast = useToast();

  const handleFormSubmit = (
    values: DoctorEmergencyContactScheme,
    helpers: FormikHelpers<DoctorEmergencyContactScheme>
  ) => {
    helpers.setSubmitting(true);

    mutation
      .mutateAsync({
        ...values,
        cellphoneNumber:
          mobilePhoneUtils.FormatNumberToRemoveSpecialChars(
            values.cellphoneNumber
          ) ?? values.cellphoneNumber,
      })
      .then(
        () => {
          toast({
            title: "Contato de emergência criado com sucesso!",
            duration: 5000,
            status: "success",
          });
          helpers.resetForm();
        },
        () => {
          toast({
            title: "Não foi criar um contato de emergência.",
            duration: 9000,
            isClosable: true,
            status: "error",
          });
        }
      )
      .finally(() => helpers.setSubmitting(false));
  };

  return (
    <Formik
      initialValues={{ name: "", cellphoneNumber: "" }}
      validationSchema={toFormikValidationSchema(doctorEmergencyContactScheme)}
      onSubmit={(values, helpers) => handleFormSubmit(values, helpers)}
    >
      {(formProps) => (
        <Form>
          <VStack alignItems="flex-start">
            <Field name="name">
              {(
                fieldProps: FieldProps<string, DoctorEmergencyContactScheme>
              ) => (
                <FormControl
                  isRequired
                  isInvalid={Boolean(
                    fieldProps.form.errors.name && fieldProps.form.touched.name
                  )}
                >
                  <FormLabel>Nome:</FormLabel>
                  <Input
                    {...fieldProps.field}
                    isDisabled={formProps.isSubmitting}
                  />
                  <FormErrorMessage>
                    {fieldProps.form.errors.name}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="cellphoneNumber">
              {(
                fieldProps: FieldProps<string, DoctorEmergencyContactScheme>
              ) => (
                <FormControl
                  isRequired
                  isInvalid={Boolean(
                    fieldProps.form.errors.cellphoneNumber &&
                      fieldProps.form.touched.cellphoneNumber
                  )}
                >
                  <FormLabel>Telefone celular:</FormLabel>
                  <Input
                    {...fieldProps.field}
                    isDisabled={formProps.isSubmitting}
                  />
                  <FormErrorMessage>
                    {fieldProps.form.errors.cellphoneNumber}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Button colorScheme="green" type="submit">
              salvar
            </Button>
          </VStack>
        </Form>
      )}
    </Formik>
  );
};
