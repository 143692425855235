import { useMutation, useQueryClient } from "react-query";
import { bankAccountService } from "../services/bank-account-service";
import { loggerService } from "../services/logger-service";

export const useBankAccountDeleteMutation = (
  doctorId?: string,
  doctorCompanyId?: string
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (accountId: string) => bankAccountService.deleteAccount(accountId),
    {
      onError: (err) => loggerService.log(err),
      onSuccess: () =>
        queryClient.refetchQueries(["bankAccounts", doctorId, doctorCompanyId]),
    }
  );
};
