import { useMutation, useQueryClient } from "react-query";
import { doctorService } from "../services/doctors-service";
import { DoctorScheme } from "../validators/doctor-scheme-validator";
import { filterNonEmptyStringProperties } from "../filters/non-empty-string-properties";
import { loggerService } from "../services/logger-service";

export const useCreateDoctorMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (doctorScheme: DoctorScheme) =>
      doctorService.createNewDoctor(
        filterNonEmptyStringProperties(doctorScheme)
      ),
    {
      onError: (err) => loggerService.log(err),
      onSuccess: () => {
        queryClient.refetchQueries(["doctors"]);
      },
    }
  );
};
