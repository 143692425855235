import { Text } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import MainContent from "../../../MainContainer";
import { Link } from "react-router-dom";
import { Content } from "./components/content";

export function CreateDoctor() {
  return (
    <MainContent>
      <Text fontSize="5xl" fontWeight={600}>
        Cadastrar novo médico
      </Text>
      <Text>
        Preencha os dados abaixo. Apenas os dados em asterisco são obrigatórios.
      </Text>
      <Link to="/medicos">
        <ArrowBackIcon boxSize={8} my="6" cursor="pointer" />
      </Link>

      <Content />
    </MainContent>
  );
}
