import { Badge, VStack } from "@chakra-ui/react";
import { Doctor } from "../../../../../models/Doctor";
import { ContentTitle } from "../../../../ContentTitle";
import { DoctorSearchCompanyName } from "./DoctorSearchCompanyName";

interface Props {
  doctor: Doctor;
}

export function DoctorRelateToCompany({ doctor }: Props) {
  return (
    <VStack w="300px" alignItems="flex-start">
      <ContentTitle title="Vinculado à empresa" />
      {doctor.doctorCompany ? (
        <VStack alignItems="flex-start">
          <Badge colorScheme="blue">{doctor.doctorCompany.razaoSocial}</Badge>
        </VStack>
      ) : (
        <DoctorSearchCompanyName doctor={doctor} />
      )}
    </VStack>
  );
}
