import { VStack, Flex, Button, Text, useToast } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { BankAccount } from "../../models/BankAccount";
import { formatter } from "../../utils/formatter";
import { bankAccountService } from "../../services/bank-account-service";
import { useBankAccountDeleteMutation } from "../../hooks/use-bank-account-delete-mutation";

interface AccountInformationProps {
  account: BankAccount;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setIsEditState: Dispatch<SetStateAction<boolean>>;
}

export default function AccountInformation({
  account,
  isLoading,
  setIsLoading,
  setIsEditState,
}: AccountInformationProps) {
  const doctorId = account.doctorId ?? undefined;
  const doctorCompanyId = account.doctorCompanyId ?? undefined;

  const toast = useToast();
  const mutation = useBankAccountDeleteMutation(doctorId, doctorCompanyId);

  const handleLiberarConsentimentoOnClick = () => {
    setIsLoading(true);
    window.location.href = bankAccountService.getBtgAuthAuthorizeUrl();
  };

  const handleDeleteOnClick = () => {
    setIsLoading(true);
    mutation
      .mutateAsync(account.id)
      .then(
        () => {
          toast({
            duration: 5000,
            title: "Conta deletada com sucesso!",
            isClosable: true,
            status: "success",
          });
        },
        () => {
          toast({
            duration: 9000,
            title: "Não foi possível deletar esta conta.",
            isClosable: true,
            status: "error",
          });
        }
      )
      .finally(() => setIsLoading(false));
  };

  const handleEditOnClick = () => setIsEditState(true);

  const isConsentFieldVisible =
    account.codigoBanco === 208 &&
    Boolean(account.doctorCompanyId) &&
    Boolean(account.bankAccountBtg);

  return (
    <VStack alignItems="flex-start" spacing={3}>
      <Flex w="50%">
        <Text minW="200px">Código do banco:</Text>
        <Text>{account.codigoBanco}</Text>
      </Flex>

      <Flex w="50%">
        <Text minW="200px">Nome do banco:</Text>
        <Text>{account.nomeBanco}</Text>
      </Flex>

      <Flex w="50%">
        <Text minW="200px">Agência:</Text>
        <Text>{account.agencia}</Text>
      </Flex>

      <Flex w="50%">
        <Text minW="200px">Dígito da agência:</Text>
        <Text>{account.agenciaDigito}</Text>
      </Flex>

      <Flex w="50%">
        <Text minW="200px">Conta corrente:</Text>
        <Text>{account.conta}</Text>
      </Flex>

      <Flex w="50%">
        <Text minW="200px">Dígito da conta:</Text>
        <Text>{account.contaDigito}</Text>
      </Flex>

      {account.chavePix && (
        <Flex w="50%">
          <Text minW="200px">Chave PIX:</Text>
          <Text>{account.chavePix}</Text>
        </Flex>
      )}

      {account.chavePix && account.tipoChavePix && (
        <Flex w="50%">
          <Text minW="200px">Tipo de chave PIX:</Text>
          <Text>{formatter.formatTipoChavePixEnum(account.tipoChavePix)}</Text>
        </Flex>
      )}

      {isConsentFieldVisible && (
        <Flex w="50%">
          <Text minW="200px">Consentimento:</Text>
          {account.bankAccountBtg ? (
            <Text>conta já consentida.</Text>
          ) : (
            <Button
              colorScheme="green"
              variant="link"
              onClick={handleLiberarConsentimentoOnClick}
              isLoading={isLoading}
            >
              liberar consentimento
            </Button>
          )}
        </Flex>
      )}

      <Flex w="50%" alignItems="center">
        <Button
          colorScheme="red"
          onClick={handleDeleteOnClick}
          isLoading={isLoading}
          mr={66}
        >
          deletar conta
        </Button>
        <Button
          colorScheme="green"
          variant="outline"
          onClick={handleEditOnClick}
          isLoading={isLoading}
        >
          editar conta
        </Button>
      </Flex>
    </VStack>
  );
}
