import { useMutation, useQueryClient } from "react-query";
import { bankAccountService } from "../services/bank-account-service";
import { BankAccountFormData } from "../components/BankAccount/AccountForm";
import { BankAccountScheme } from "../validators/bank-account-scheme-validator";
import { loggerService } from "../services/logger-service";

function mapFormDataToPayload(
  formData: BankAccountFormData,
  doctorId?: string,
  doctorCompanyId?: string
): BankAccountScheme {
  return {
    agencia: Number(formData.agencia),
    codigoBanco: Number(formData.codigoBanco),
    conta: Number(formData.conta),
    agenciaDigito: formData.agenciaDigito
      ? Number(formData.agenciaDigito)
      : undefined,
    chavePix: formData.chavePix ? formData.chavePix : undefined,
    contaDigito: formData.contaDigito
      ? Number(formData.contaDigito)
      : undefined,
    nomeBanco: formData.nomeBanco ? formData.nomeBanco : undefined,
    tipoChavePix: formData.tipoChavePix ? formData.tipoChavePix : undefined,
    doctorCompanyId,
    doctorId,
  };
}

interface Params {
  doctorId?: string;
  doctorCompanyId?: string;
}

export const useBankAccountCreateMutation = ({
  doctorCompanyId,
  doctorId,
}: Params) => {
  const queryClient = useQueryClient();

  return useMutation(
    (formData: BankAccountFormData) =>
      bankAccountService.createAccount(
        mapFormDataToPayload(formData, doctorId, doctorCompanyId)
      ),
    {
      onError: (err) => loggerService.log(err),
      onSuccess: () => {
        queryClient.refetchQueries(["bankAccounts", doctorId, doctorCompanyId]);
      },
    }
  );
};
