import axios from "axios";
import { API_HOST } from "../config/env";

class LysimedUsersService {
  private readonly baseUrl: string;

  constructor() {
    const baseUrlFromEnv = API_HOST;

    if (!baseUrlFromEnv) {
      throw new Error(
        "LysimedApiAuthService: Lysimed API Base Url is not defined"
      );
    }

    this.baseUrl = baseUrlFromEnv;
  }

  public async forgotPassword(email: string) {
    return axios
      .post(`${this.baseUrl}/auth/forgot-password`, { email })
      .then((res) => res.data);
  }

  public async changePassword(token: string, password: string) {
    return axios
      .post(`${this.baseUrl}/auth/change-password`, { token, password })
      .then((res) => res.data);
  }
}

export const lysimedUsersService = new LysimedUsersService();
