import { AxiosInstance } from "axios";
import { DoctorEmergencyContact } from "../models/DoctorEmergencyContact";
import { DoctorEmergencyContactScheme } from "../validators/doctor-emergency-contact-validator";
import { API_HOST } from "../config/env";
import { httpClient } from "../clients/http-client";

interface CreateNewContactPayload extends DoctorEmergencyContactScheme {
  doctorId: string;
}

class DoctorEmergencyContactService {
  private readonly baseUrl: string;
  private readonly httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    const baseUrlFromEnv = API_HOST;

    if (!baseUrlFromEnv) {
      throw new Error(
        "DoctorEmergencyContactService: Lysimed API Base Url is not defined"
      );
    }

    this.baseUrl = baseUrlFromEnv;
    this.httpClient = httpClient;
  }

  public async createNewContact(data: CreateNewContactPayload) {
    return this.httpClient.post<DoctorEmergencyContact>(
      `${this.baseUrl}/doctor-emergency-contact`,
      data,
      {
        withCredentials: true,
      }
    );
  }

  public async getEmergencyContacts(doctorId?: string) {
    const searchParams = new URLSearchParams();

    if (doctorId) searchParams.append("doctorId", doctorId);

    return this.httpClient.get<DoctorEmergencyContact[]>(
      `${this.baseUrl}/doctor-emergency-contact?${searchParams.toString()}`,
      {
        withCredentials: true,
      }
    );
  }
}

export const doctorEmergencyContactService = new DoctorEmergencyContactService(
  httpClient
);
