export function filterNonEmptyStringProperties(obj: { [key: string]: string }) {
  const filteredObject: { [key: string]: string } = {};

  for (const key in obj) {
    if (obj[key] !== "") {
      filteredObject[key] = obj[key];
    }
  }

  return filteredObject;
}
