import dayjs from "dayjs";

class DateUtil {
  private readonly engine = dayjs;

  public addDaysToCurrentDate(daysToAdd: number): Date {
    return this.engine().add(daysToAdd, "day").toDate();
  }

  public formatISODateStringToLocale(isoDate: string | null) {
    if (!isoDate) return "";
    return new Date(isoDate).toLocaleDateString("pt-BR");
  }

  public formatChakraDatetime(isoDatetime: string | null): string {
    if (!isoDatetime) return "";

    const date = new Date(isoDatetime);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    const formattedDatetime = `${year}-${month}-${day}`;

    return formattedDatetime;
  }

  public convertChakraDateTimeToISO(datetimeString: string): string {
    const [year, month, day] = datetimeString.split("-").map(Number);
    const isoDatetime = new Date(year, month - 1, day).toISOString();

    return isoDatetime;
  }
}

export const dateUtil = new DateUtil();
