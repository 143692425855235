import { z } from "zod";
import { mobilePhoneUtils } from "../utils/mobile-phone";

export const doctorEmergencyContactScheme = z.object({
  name: z.string({ required_error: "Nome é um campo obrigatório" }),
  cellphoneNumber: z.custom<string>(
    (data) => mobilePhoneUtils.isValidMobilePhone(data as string),
    "Forneça um telefone celular válido."
  ),
});

export type DoctorEmergencyContactScheme = z.infer<
  typeof doctorEmergencyContactScheme
>;
