import { useMutation, useQueryClient } from "react-query";
import { DoctorScheme } from "../validators/doctor-scheme-validator";
import { doctorService } from "../services/doctors-service";
import { filterNonEmptyStringProperties } from "../filters/non-empty-string-properties";
import { loggerService } from "../services/logger-service";

export const useEditDoctorMutation = (doctorId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: DoctorScheme) =>
      doctorService.editDoctor(doctorId, filterNonEmptyStringProperties(data)),
    {
      onError: (err) => loggerService.log(err),
      onSuccess: (res) => {
        queryClient.setQueryData(["doctorById", doctorId], res.data);
      },
    }
  );
};
