import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { toFormikValidationSchema } from "zod-formik-adapter";
import {
  DoctorScheme,
  doctorScheme,
} from "../../../../validators/doctor-scheme-validator";
import { EstadoCivil, RegimeDeBens } from "../../../../models/Doctor";
import { formatter } from "../../../../utils/formatter";
import { dateUtil } from "../../../../utils/date";

interface Props {
  dateInputValue: string;
  initialValues: DoctorScheme;
  setDateInputValue: React.Dispatch<React.SetStateAction<string>>;
  setIsEditState?: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (
    values: DoctorScheme,
    helpers: FormikHelpers<DoctorScheme>
  ) => void;
}

export function DoctorForm({
  dateInputValue,
  initialValues,
  setDateInputValue,
  setIsEditState,
  onSubmit,
}: Props) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={toFormikValidationSchema(doctorScheme)}
      onSubmit={(values, helpers) => onSubmit(values, helpers)}
    >
      {(formProps) => (
        <Form>
          <Box mb={2}>
            <Field name="name">
              {(fieldProps: FieldProps<string, DoctorScheme>) => (
                <FormControl
                  isInvalid={Boolean(
                    fieldProps.form.errors.name && fieldProps.form.touched.name
                  )}
                  isRequired
                >
                  <FormLabel>Nome:</FormLabel>
                  <Input
                    {...fieldProps.field}
                    isDisabled={formProps.isSubmitting}
                  />
                  <FormErrorMessage>
                    {fieldProps.form.errors.name}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Box>

          <Box mb={2}>
            <Field name="email">
              {(fieldProps: FieldProps<string, DoctorScheme>) => (
                <FormControl
                  isInvalid={Boolean(
                    fieldProps.form.errors.email &&
                      fieldProps.form.touched.email
                  )}
                >
                  <FormLabel>E-mail:</FormLabel>
                  <Input {...fieldProps.field} />
                  <FormErrorMessage>
                    {fieldProps.form.errors.email}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Box>

          <Box mb={2}>
            <Field name="cpf">
              {(fieldProps: FieldProps<string, DoctorScheme>) => (
                <FormControl
                  isInvalid={Boolean(
                    fieldProps.form.errors.cpf && fieldProps.form.touched.cpf
                  )}
                >
                  <FormLabel>CPF:</FormLabel>
                  <Input
                    {...fieldProps.field}
                    value={fieldProps.field.value}
                    isDisabled={formProps.isSubmitting}
                  />
                  <FormErrorMessage>
                    {fieldProps.form.errors.cpf}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Box>

          <Box mb={2}>
            <Field name="cellphoneNumber">
              {(fieldProps: FieldProps<string, DoctorScheme>) => (
                <FormControl
                  isInvalid={Boolean(
                    fieldProps.form.errors.cellphoneNumber &&
                      fieldProps.form.touched.cellphoneNumber
                  )}
                >
                  <FormLabel>Telefone pessoal:</FormLabel>
                  <Input
                    {...fieldProps.field}
                    value={fieldProps.field.value}
                    isDisabled={formProps.isSubmitting}
                  />
                  <FormErrorMessage>
                    {fieldProps.form.errors.cellphoneNumber}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Box>

          <Box mb={2}>
            <Field name="crm">
              {(fieldProps: FieldProps<string, DoctorScheme>) => (
                <>
                  <FormLabel>CRM:</FormLabel>
                  <Input
                    {...fieldProps.field}
                    isDisabled={formProps.isSubmitting}
                  />
                </>
              )}
            </Field>
          </Box>

          <Box mb={2}>
            <Field name="crmSuplementar">
              {(fieldProps: FieldProps<string, DoctorScheme>) => (
                <>
                  <FormLabel>CRM Suplementar:</FormLabel>
                  <Input
                    {...fieldProps.field}
                    isDisabled={formProps.isSubmitting}
                  />
                </>
              )}
            </Field>
          </Box>

          <Box mb={2}>
            <Field name="rg">
              {(fieldProps: FieldProps<string, DoctorScheme>) => (
                <>
                  <FormLabel>RG:</FormLabel>
                  <Input
                    {...fieldProps.field}
                    isDisabled={formProps.isSubmitting}
                  />
                </>
              )}
            </Field>
          </Box>

          <Box mb={2}>
            <Field name="orgaoEmissorRg">
              {(fieldProps: FieldProps<string, DoctorScheme>) => (
                <>
                  <FormLabel>Órgão emissor (RG):</FormLabel>
                  <Input
                    {...fieldProps.field}
                    isDisabled={formProps.isSubmitting}
                  />
                </>
              )}
            </Field>
          </Box>

          <Box mb={2}>
            <Field name="pis">
              {(fieldProps: FieldProps<string, DoctorScheme>) => (
                <>
                  <FormLabel>PIS:</FormLabel>
                  <Input
                    {...fieldProps.field}
                    isDisabled={formProps.isSubmitting}
                  />
                </>
              )}
            </Field>
          </Box>

          <Box mb={2}>
            <Field name="nationality">
              {(fieldProps: FieldProps<string, DoctorScheme>) => (
                <>
                  <FormLabel>Nacionalidade:</FormLabel>
                  <Input
                    {...fieldProps.field}
                    isDisabled={formProps.isSubmitting}
                  />
                </>
              )}
            </Field>
          </Box>

          <Box mb={2}>
            <Field name="dateOfBirth">
              {(fieldProps: FieldProps<string, DoctorScheme>) => (
                <>
                  <FormLabel>Data de nascimento:</FormLabel>
                  <Input
                    {...fieldProps.field}
                    isDisabled={formProps.isSubmitting}
                    value={dateInputValue}
                    type="date"
                    onChange={(event) => setDateInputValue(event.target.value)}
                    onBlur={() =>
                      fieldProps.form.setFieldValue(
                        "dateOfBirth",
                        dateUtil.convertChakraDateTimeToISO(dateInputValue)
                      )
                    }
                  />
                </>
              )}
            </Field>
          </Box>

          <Box mb={2}>
            <Field name="estadoCivil">
              {(fieldProps: FieldProps<string, DoctorScheme>) => (
                <>
                  <FormLabel>Estado civil:</FormLabel>
                  <Select
                    {...fieldProps.field}
                    isDisabled={formProps.isSubmitting}
                  >
                    {Object.values(EstadoCivil).map((estadoCivil) => (
                      <option key={estadoCivil} value={estadoCivil}>
                        {formatter.formatEstadoCivil(estadoCivil)}
                      </option>
                    ))}
                  </Select>
                </>
              )}
            </Field>
          </Box>

          {(formProps.values.estadoCivil === EstadoCivil.CASADO ||
            formProps.values.estadoCivil === EstadoCivil.UNIAO_ESTAVEL) && (
            <Box mb={2}>
              <Field name="regimeDeBens">
                {(fieldProps: FieldProps<string, DoctorScheme>) => (
                  <>
                    <FormLabel>Regime de bens:</FormLabel>
                    <Select
                      {...fieldProps.field}
                      isDisabled={formProps.isSubmitting}
                    >
                      {Object.values(RegimeDeBens).map((regimeDeBens) => (
                        <option key={regimeDeBens} value={regimeDeBens}>
                          {formatter.formatRegimeDeBens(regimeDeBens)}
                        </option>
                      ))}
                    </Select>
                  </>
                )}
              </Field>
            </Box>
          )}

          <Flex alignItems="center" mt={5}>
            <Button
              isLoading={formProps.isSubmitting}
              colorScheme="green"
              type="submit"
            >
              salvar
            </Button>

            {setIsEditState && (
              <Button
                variant="ghost"
                colorScheme="green"
                onClick={() => setIsEditState(false)}
              >
                cancelar
              </Button>
            )}
          </Flex>
        </Form>
      )}
    </Formik>
  );
}
