import { useQuery } from "react-query";
import { doctorCompaniesService } from "../services/doctor-companies-service";
import { loggerService } from "../services/logger-service";

export const useDoctorCompanyAutocompleteQuery = (name?: string) => {
  const { data } = useQuery(
    ["doctorCompanyAutocomplete", name],
    () => doctorCompaniesService.getCompanyNames(name),
    {
      refetchOnWindowFocus: false,
      staleTime: 150000,
      onError: (err) => loggerService.log(err),
    }
  );

  return { data: data?.data };
};
