import { AxiosInstance } from "axios";
import { Doctor } from "../models/Doctor";
import { API_HOST } from "../config/env";
import { httpClient } from "../clients/http-client";

interface GetDoctorsResponse {
  page: number;
  pages: number;
  doctors: Doctor[];
}

class DoctorsService {
  private readonly baseUrl: string;
  private readonly httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    const baseUrlFromEnv = API_HOST;

    if (!baseUrlFromEnv) {
      throw new Error("DoctorsService: Lysimed API Base Url is not defined");
    }

    this.baseUrl = baseUrlFromEnv;
    this.httpClient = httpClient;
  }

  public async createNewDoctor(doctorScheme: unknown) {
    return this.httpClient.post<Doctor>(
      `${this.baseUrl}/doctors`,
      doctorScheme,
      {
        withCredentials: true,
      }
    );
  }

  public async getDoctors(name?: string, page?: number) {
    const queryParams = new URLSearchParams();

    if (name) queryParams.append("name", name);
    if (page) queryParams.append("page", page.toString());

    return this.httpClient.get<GetDoctorsResponse>(
      `${this.baseUrl}/doctors?${queryParams.toString()}`,
      {
        withCredentials: true,
      }
    );
  }

  public async getDoctorById(id: string) {
    return this.httpClient.get<Doctor>(`${this.baseUrl}/doctors/${id}`, {
      withCredentials: true,
    });
  }

  public async editDoctor(doctorId: string, data: unknown) {
    return this.httpClient.patch<Doctor>(
      `${this.baseUrl}/doctors/${doctorId}`,
      data,
      {
        withCredentials: true,
      }
    );
  }

  public async relateDoctorToCompany(doctorId: string, companyId: string) {
    return this.httpClient.patch<Doctor>(
      `${this.baseUrl}/doctors/${doctorId}/doctor-company/${companyId}`,
      {},
      {
        withCredentials: true,
      }
    );
  }
}

export const doctorService = new DoctorsService(httpClient);
