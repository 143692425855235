import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  useToast,
} from "@chakra-ui/react";
import { TipoChavePix } from "../../models/BankAccount";
import { brasilApiService } from "../../services/brasil-api-service";
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from "formik";
import { useBankAccountCreateMutation } from "../../hooks/use-bank-account-create-mutation";
import { loggerService } from "../../services/logger-service";

export interface BankAccountFormData {
  agencia: string;
  agenciaDigito?: string;
  conta: string;
  contaDigito?: string;
  codigoBanco: string;
  nomeBanco?: string;
  chavePix?: string;
  tipoChavePix?: TipoChavePix;
}

interface Props {
  doctorId?: string;
  doctorCompanyId?: string;
}

export default function AccountForm({ doctorCompanyId, doctorId }: Props) {
  const toast = useToast();
  const mutation = useBankAccountCreateMutation({ doctorCompanyId, doctorId });

  const initialValue: BankAccountFormData = {
    agencia: "",
    agenciaDigito: "",
    codigoBanco: "",
    conta: "",
    contaDigito: "",
    nomeBanco: "",
    chavePix: "",
    tipoChavePix: TipoChavePix.CNPJ,
  };

  const handleCodigoDoBancoOnBlur = async (
    formProps: FormikProps<BankAccountFormData>
  ) => {
    formProps.setSubmitting(true);

    await brasilApiService
      .getBank(Number(formProps.values.codigoBanco))
      .then(
        (response) => {
          formProps.setFieldValue("nomeBanco", response.data.fullName);
        },
        () =>
          toast({
            title: "Não foi encontrado nenhum banco com este código.",
            status: "error",
            duration: 9000,
            isClosable: true,
          })
      )
      .catch((error) => loggerService.log(error))
      .finally(() => formProps.setSubmitting(false));
  };

  const formValidate = (values: BankAccountFormData) => {
    const errors: Partial<BankAccountFormData> = {};
    const { agencia, codigoBanco, conta } = values;
    if (agencia === "") {
      errors.agencia = "Campo obrigatório";
    }
    if (codigoBanco === "") {
      errors.codigoBanco = "Campo obrigatório";
    }
    if (conta === "") {
      errors.conta = "Campo obrigatório";
    }
    return errors;
  };

  const handleSubmit = (
    values: BankAccountFormData,
    helpers: FormikHelpers<BankAccountFormData>
  ) => {
    helpers.setSubmitting(true);

    mutation
      .mutateAsync(values)
      .then(
        () => {
          toast({
            title: "Conta corrente criada com sucesso!",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          helpers.resetForm();
        },
        () =>
          toast({
            title: "Erro na criação de conta corrente.",
            status: "error",
            duration: 9000,
            isClosable: true,
          })
      )
      .catch((error) => loggerService.log(error))
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValue}
      validate={formValidate}
      onSubmit={(values, helpers) => handleSubmit(values, helpers)}
    >
      {(formProps) => (
        <Form>
          <Flex gap={5} wrap="wrap" justifyItems="space-between" w="950px">
            <Field name="codigoBanco">
              {(fieldProps: FieldProps<string, BankAccountFormData>) => (
                <FormControl
                  isRequired
                  isInvalid={Boolean(
                    fieldProps.form.errors.codigoBanco &&
                      fieldProps.form.touched.codigoBanco
                  )}
                >
                  <FormLabel width="150px">Código do banco:</FormLabel>
                  <Input
                    {...fieldProps.field}
                    onBlur={() => handleCodigoDoBancoOnBlur(formProps)}
                    isDisabled={formProps.isSubmitting}
                  />
                  <FormErrorMessage>
                    {fieldProps.form.errors.codigoBanco}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="nomeBanco">
              {(fieldProps: FieldProps<string, BankAccountFormData>) => (
                <FormControl
                  isInvalid={Boolean(
                    fieldProps.form.errors.nomeBanco &&
                      fieldProps.form.touched.nomeBanco
                  )}
                >
                  <FormLabel width="150px">Nome do banco:</FormLabel>
                  <Input
                    {...fieldProps.field}
                    isDisabled={formProps.isSubmitting}
                  />
                  <FormErrorMessage>
                    {fieldProps.form.errors.nomeBanco}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Flex>
              <Field name="agencia">
                {(fieldProps: FieldProps<string, BankAccountFormData>) => (
                  <FormControl
                    isRequired
                    isInvalid={Boolean(
                      fieldProps.form.errors.agencia &&
                        fieldProps.form.touched.agencia
                    )}
                  >
                    <FormLabel width="150px">Agência:</FormLabel>
                    <Input
                      {...fieldProps.field}
                      isDisabled={formProps.isSubmitting}
                    />
                    <FormErrorMessage>
                      {fieldProps.form.errors.agencia}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="agenciaDigito">
                {(fieldProps: FieldProps<string, BankAccountFormData>) => (
                  <FormControl
                    isInvalid={Boolean(
                      fieldProps.form.errors.agenciaDigito &&
                        fieldProps.form.touched.agenciaDigito
                    )}
                  >
                    <FormLabel width="150px">Dígito da agência:</FormLabel>
                    <Input
                      {...fieldProps.field}
                      isDisabled={formProps.isSubmitting}
                    />
                    <FormErrorMessage>
                      {fieldProps.form.errors.agenciaDigito}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex>
              <Field name="conta">
                {(fieldProps: FieldProps<string, BankAccountFormData>) => (
                  <FormControl
                    isRequired
                    isInvalid={Boolean(
                      fieldProps.form.errors.conta &&
                        fieldProps.form.touched.conta
                    )}
                  >
                    <FormLabel width="150px">Conta corrente:</FormLabel>
                    <Input
                      {...fieldProps.field}
                      isDisabled={formProps.isSubmitting}
                    />
                    <FormErrorMessage>
                      {fieldProps.form.errors.conta}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="contaDigito">
                {(fieldProps: FieldProps<string, BankAccountFormData>) => (
                  <FormControl
                    isInvalid={Boolean(
                      fieldProps.form.errors.contaDigito &&
                        fieldProps.form.touched.contaDigito
                    )}
                  >
                    <FormLabel width="150px">Dígito da conta:</FormLabel>
                    <Input
                      {...fieldProps.field}
                      isDisabled={formProps.isSubmitting}
                    />
                    <FormErrorMessage>
                      {fieldProps.form.errors.contaDigito}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex>
              <Field name="chavePix">
                {(fieldProps: FieldProps<string, BankAccountFormData>) => (
                  <FormControl
                    isInvalid={Boolean(
                      fieldProps.form.errors.chavePix &&
                        fieldProps.form.touched.chavePix
                    )}
                  >
                    <FormLabel width="150px">Chave Pix:</FormLabel>
                    <Input
                      {...fieldProps.field}
                      isDisabled={formProps.isSubmitting}
                    />
                    <FormErrorMessage>
                      {fieldProps.form.errors.chavePix}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="tipoChavePix">
                {(fieldProps: FieldProps<string, BankAccountFormData>) => (
                  <FormControl
                    isInvalid={Boolean(
                      fieldProps.form.errors.tipoChavePix &&
                        fieldProps.form.touched.tipoChavePix
                    )}
                  >
                    <FormLabel width="150px">Tipo Chave Pix:</FormLabel>
                    <Select
                      {...fieldProps.field}
                      isDisabled={formProps.isSubmitting}
                    >
                      <option value={TipoChavePix.EMAIL}>Email</option>
                      <option value={TipoChavePix.TELEFONE}>Telefone</option>
                      <option value={TipoChavePix.CPF}>Cpf</option>
                      <option value={TipoChavePix.HASH}>Chave aleatória</option>
                      <option value={TipoChavePix.CNPJ}>CNPJ</option>
                    </Select>
                    <FormErrorMessage>
                      {fieldProps.form.errors.tipoChavePix}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Flex>
          </Flex>
          <Button
            type="submit"
            colorScheme="green"
            mt={2}
            isLoading={formProps.isSubmitting}
          >
            salvar
          </Button>
        </Form>
      )}
    </Formik>
  );
}
