import { API_HOST } from "../../config/env";
import { useBankAccountsQuery } from "../../hooks/use-bank-accounts-query";
import { useDoctorCompanyAutocompleteQuery } from "../../hooks/use-doctor-company-autocomplete-query";
import MainContent from "../MainContainer";
import { Button, Table, Tbody, Text, Th, Thead, Tr } from "@chakra-ui/react";

export const BankAccount = () => {
  const { data } = useBankAccountsQuery();
  const { data: companyList } = useDoctorCompanyAutocompleteQuery();

  return (
    <MainContent>
      <Text fontSize="5xl" fontWeight={600}>
        Contas Bancárias
      </Text>

      <Text>Todas as contas bancárias das empresas médicas</Text>

      <Table maxWidth={1024}>
        <Thead>
          <Tr>
            <Th>Empresa</Th>
            <Th>Agência</Th>
            <Th>Conta</Th>
            <Th>Consentimento</Th>
          </Tr>
        </Thead>

        <Tbody>
          {data?.map((bankAccount) => {
            const company = companyList?.find(
              (company) => company.id === bankAccount.doctorCompanyId
            );

            return (
              <Tr key={bankAccount.id}>
                <Th>{company?.razaoSocial}</Th>
                <Th>{bankAccount.agencia}</Th>
                <Th>
                  {bankAccount.conta}-{bankAccount.contaDigito}
                </Th>
                <Th>
                  {bankAccount.bankAccountBtg ? null : (
                    <Button
                      as="a"
                      href={`${API_HOST}/btg-bank-account/authorize?callback=${window.location.pathname}`}
                      colorScheme="green"
                    >
                      consentir
                    </Button>
                  )}
                </Th>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </MainContent>
  );
};
