import { Box, Button, Input, Text, useToast } from "@chakra-ui/react";
import { ChangeEvent, FormEvent, useState } from "react";
import { lysimedUsersService } from "../../services/users-service";
import { useNavigate, useSearchParams } from "react-router-dom";

export const ChangePasswordPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const [state, setState] = useState<
    "send_email" | "change_password" | "success"
  >(token ? "change_password" : "send_email");

  const [emailInputValue, setEmailInputValue] = useState<string>("");
  const [passwordInputValue, setPasswordInputValue] = useState<string>("");
  const [confirmPasswordInputValue, setConfirmPasswordInputValue] =
    useState<string>("");
  const [isloading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();
  const navigate = useNavigate();

  function handleSendEmailFormSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    setIsLoading(true);

    if (emailInputValue === "") {
      toast({
        title: "Erro",
        description: "Digite um e-mail",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false);
      return;
    }

    lysimedUsersService
      .forgotPassword(emailInputValue)
      .then(() => {
        toast({
          title: "E-mail enviado",
          description: "Verifique sua caixa de entrada",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setState("success");
      })
      .catch(() => {
        toast({
          title: "Erro",
          description: "Não foi possível enviar o e-mail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => setIsLoading(false));
  }

  function handleEmailInputOnChange(
    event: ChangeEvent<HTMLInputElement>
  ): void {
    setEmailInputValue(event.target.value);
  }

  function handleChangePasswordFormSubmit(
    event: FormEvent<HTMLFormElement>
  ): void {
    event.preventDefault();
    setIsLoading(true);

    if (passwordInputValue !== confirmPasswordInputValue) {
      toast({
        title: "Erro",
        description: "As senhas não coincidem",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false);
      return;
    }

    if (!token) {
      toast({
        title: "Erro",
        description: "Token não encontrado",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false);

      throw new Error("Token não encontrado");
    }

    lysimedUsersService
      .changePassword(token, passwordInputValue)
      .then(() => {
        toast({
          title: "Senha alterada",
          description: "Sua senha foi alterada com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setPasswordInputValue("");
        setConfirmPasswordInputValue("");
        navigate("/");
      })
      .catch(() => {
        toast({
          title: "Erro",
          description: "Não foi possível alterar a senha",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => setIsLoading(false));
  }

  function handlePasswordInputOnChange(
    event: ChangeEvent<HTMLInputElement>
  ): void {
    setPasswordInputValue(event.target.value);
  }

  function handleConfirmPasswordInputOnChange(
    event: ChangeEvent<HTMLInputElement>
  ): void {
    setConfirmPasswordInputValue(event.target.value);
  }

  return (
    <Box as="main" height="100vh" width={300} mx="auto" display="flex">
      <Box alignSelf="center">
        {state === "send_email" && (
          <form onSubmit={handleSendEmailFormSubmit}>
            <Text fontSize="4xl">Digite seu e-mail</Text>
            <Input
              type="email"
              id="e-mail"
              name="e-mail"
              value={emailInputValue}
              onChange={handleEmailInputOnChange}
              disabled={isloading}
            />
            <Button
              type="submit"
              colorScheme="green"
              mt={2}
              disabled={isloading}
            >
              Enviar
            </Button>
          </form>
        )}

        {state === "change_password" && (
          <form onSubmit={handleChangePasswordFormSubmit}>
            <Text fontSize="4xl">Mude a sua senha</Text>
            <Input
              type="password"
              placeholder="Defina a sua nova senha"
              id="password"
              name="password"
              value={passwordInputValue}
              onChange={handlePasswordInputOnChange}
              disabled={isloading}
            />
            <Input
              mt={2}
              type="password"
              placeholder="Confirme a sua nova senha"
              id="password_confirmation"
              name="password_confirmation"
              value={confirmPasswordInputValue}
              onChange={handleConfirmPasswordInputOnChange}
              disabled={isloading}
            />
            <Button
              type="submit"
              colorScheme="green"
              mt={2}
              disabled={isloading}
            >
              Confirmar
            </Button>
          </form>
        )}

        {state === "success" && (
          <Text fontSize="4xl">E-mail enviado com sucesso</Text>
        )}
      </Box>
    </Box>
  );
};
